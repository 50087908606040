import React, { useState } from "react";

import VibeTitle from "@/components/VibeTitle";
import { DoubleCheckPassword } from "@/components/CustomForm";

import constants from "@/constants";
import Log from "@/utils/Log";
import { useAuth } from "@/hooks";
import CustomLayout from "@/components/CustomLayout";
import CustomInput from "@/components/CustomInput";

const REDIRECT_DELAY = 3000; // add password and wait 3s continue auth process;

const SET_STATE = {
  LOADING: "LOADING",
  SET_PASSWORD: "SET_PASSWORD",
  SET_SUCCESS: "SET_SUCCESS",
} as const;

type TSetState = keyof typeof SET_STATE;

const SetPasswordLoading = ({
  onContinue,
  onSkip,
}: {
  onContinue: VoidFunction;
  onSkip: VoidFunction;
}) => (
  <CustomLayout contentClassName="flex-wrapper">
    <VibeTitle
      title="Set password for your Vibe account"
      subtitle="Use a password to secure your account. However, you can still opt to use an email verification code."
    />
    <button className="button-primary-360" onClick={onContinue}>
      Set password
    </button>
    <button className="link-text" onClick={onSkip}>
      Skip
    </button>
  </CustomLayout>
);

const SetNewPassword = ({
  email,
  onSentSuccess,
  onSkip,
}: {
  email: string;
  onSentSuccess: VoidFunction;
  onSkip: VoidFunction;
}) => {
  const auth = useAuth();

  function handlePasswordChecked(newPassword: string) {
    auth.addNewPassword(newPassword).then((addRes) => {
      Log.debug("add new password res: ", addRes);
      if (addRes) {
        onSentSuccess();
      }
    });
  }

  return (
    <CustomLayout>
      <VibeTitle title="Set a password" subtitle="Set a secure password for your Vibe account." />
      <CustomInput type="email" value={email} disabled />
      <DoubleCheckPassword email={email} onPasswordChecked={handlePasswordChecked} />
      <button className="link-text" onClick={onSkip}>
        Cancel
      </button>
    </CustomLayout>
  );
};

const SentPasswordSuccess = () => (
  <CustomLayout contentClassName="flex-wrapper">
    <VibeTitle
      title="Your password has been set."
      subtitle="You can now sign in to your Vibe account."
    />
  </CustomLayout>
);

const SetPassword = () => {
  const [currentState, setState] = useState<TSetState>(SET_STATE.LOADING);
  const auth = useAuth();
  const email = auth?.authState?.email || sessionStorage.getItem(constants.EMAIL_KEY) || "";

  function handleSentSuccess() {
    setState(SET_STATE.SET_SUCCESS);
    setTimeout(() => {
      auth.selectWorkspaceProcess();
    }, REDIRECT_DELAY);
  }

  function handleSkipClicked() {
    auth.selectWorkspaceProcess();
  }

  function handleContinueClicked() {
    if (currentState !== SET_STATE.LOADING) {
      return;
    }
    setState(SET_STATE.SET_PASSWORD);
  }

  switch (currentState) {
    case SET_STATE.SET_SUCCESS:
      return <SentPasswordSuccess />;
    case SET_STATE.SET_PASSWORD:
      return (
        <SetNewPassword
          email={email}
          onSentSuccess={handleSentSuccess}
          onSkip={handleSkipClicked}
        />
      );
    default:
      return <SetPasswordLoading onContinue={handleContinueClicked} onSkip={handleSkipClicked} />;
  }
};

export default SetPassword;
