import React, { useRef, useState, useEffect } from "react";

import CustomInput, { INPUT_INVALID_STATUS, TInputInValidStatus } from "../CustomInput";
import { useAuth } from "@/hooks";
import Log from "@/utils/Log";
import { passwordCheckInvalidHint } from "./invalidInputHints";

const CheckPassword = ({ email }: { email: string; initialFocus?: boolean }) => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>("");
  const [invalidStatus, setInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  const auth = useAuth();

  function testInputValidation(password: string) {
    if (!password) {
      setInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handlePasswordInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e?.target?.value || "";
    setPassword(password);
    if (invalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testInputValidation(password);
    }
  }

  function handlePasswordFinished(e?: React.FormEvent) {
    e?.preventDefault();
    if (testInputValidation(password)) {
      Log.debug("password is: ", password);
      auth.signInWithPassword(email, password).then((isAuthed) => {
        if (!isAuthed) {
          setInvalidStatus(INPUT_INVALID_STATUS.INVALID);
        }
      });
    }
  }

  useEffect(() => {
    passwordInputRef?.current?.focus();
  }, []);

  return (
    <form onSubmit={handlePasswordFinished}>
      <input autoComplete="email" className="is-display-none" type="email" defaultValue={email} />
      <CustomInput
        inputRef={passwordInputRef}
        type="password"
        value={password}
        onChange={handlePasswordInputChange}
        onEnter={handlePasswordFinished}
        invalidHintContent={passwordCheckInvalidHint(invalidStatus)}
      />
      <button className="button-primary-360" type="submit">
        Continue
      </button>
    </form>
  );
};

export default CheckPassword;
