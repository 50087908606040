import { ApiResponse, ITeamInfo, ITeamInfoObj, IUserInfo, User } from "../interface";
import { Get, Post } from "../server";

export function getUserInfo<T = IUserInfo>(): ApiResponse<T> {
  return Get<T>("/users/me");
}

type ITeamInfoArrayObj = { teams: ITeamInfo[] };
export function getTeamsInfo(): ApiResponse<ITeamInfoObj[]> {
  return new Promise((resolve, reject) => {
    Get<ITeamInfoArrayObj>("/teams/teams")
      .then((response) => {
        let teamInfoList = new Array<ITeamInfoObj>();
        if (response?.teams && Array.isArray(response.teams)) {
          teamInfoList = response.teams.map((team: ITeamInfo) => {
            return {
              teamId: team.team_id,
              displayName: team.display_name,
              personal: team.personal,
              iconUrl: team.icon_url,
              role: team.role,
              defaultPolicy: team.default_policy,
              domain: team.domain,
              memberNum: team.total,
            };
          });
        }
        resolve(teamInfoList);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamMemberInfo<T = { users: User[] }>(teamId: string): ApiResponse<T> {
  return Get<T>(`teams/team/${teamId}/users`);
}

export function updateWorkspaceName<T = { team_id: string }>(teamName: string): ApiResponse<T> {
  return Post<T>("/teams/team", {
    display_name: teamName,
  });
}

export function userLoginStatus<T = { registered: boolean; sso_url: string }>(
  email: string,
  captcha_token: string
): ApiResponse<T> {
  return Get<T>("/users/registration/status", {
    email,
    captcha_token,
  });
}

export function userLoginAndRegister<
  T = { check_code?: string; email_status: string; sso_url?: string },
>(email: string, captcha_token: string): ApiResponse<T> {
  return Post<T>("/users/registration", {
    email,
    captcha_token,
  });
}

export function requestResetPassword<T>(email: string, captcha_token: string): ApiResponse<T> {
  return Post<T>("/users/password/reset-email", { email, captcha_token });
}

export function resetPassword<T>(
  email: string,
  requestId: string,
  newPassword: string
): ApiResponse<T> {
  return Post<T>("/users/password/reset", {
    email: email,
    request_id: requestId,
    new_password: newPassword,
  });
}

export function updatePassword<T>(oldPassword: string, newPassword: string): ApiResponse<T> {
  return Post<T>("/users/password", {
    old_password: oldPassword,
    new_password: newPassword,
  });
}

// email login related
export function checkEmailLoginStatus<T = { validated: boolean; access_token: string }>(
  checkCode: string
): ApiResponse<T> {
  return Post<T>("/users/registration/check", { check_code: checkCode });
}

export function emailApproval<T>(
  checkCode: string,
  registerCode: string,
  newPassword?: string
): ApiResponse<T> {
  return Post<T>("/users/registration/login", {
    check_code: checkCode,
    register_code: registerCode,
    new_password: newPassword,
  });
}

export function register<T>(
  userName: string,
  checkCode: string,
  registerCode: string
): ApiResponse<T> {
  return Post<T>("/users/registration/register", {
    check_code: checkCode,
    register_code: registerCode,
    user_info: {
      display_name: userName,
    },
  });
}

export function tvRegister<T>(userName: string, requestId: string): ApiResponse<T> {
  return Post<T>("/users/registration/im-register", {
    im_request_id: requestId,
    user_info: {
      display_name: userName,
    },
  });
}

export function registerWithPassword<T = { access_token?: string }>(
  email: string,
  username?: string,
  password?: string,
  checkCode?: string,
  registerCode?: string
): ApiResponse<T> {
  let userInfo;
  if (username && password) {
    userInfo = { display_name: username, password: password };
  }
  return Post<T>("/users/registration/register", {
    email,
    user_info: userInfo,
    check_code: checkCode,
    register_code: registerCode,
  });
}

export function getRegistrationCheckCode<
  T = {
    check_code: string;
  },
>(email: string, captcha_token: string): ApiResponse<T> {
  return Post<T>("/users/registration/register/send-code", { email, captcha_token });
}

export function loginWithCode<T = { access_token: string }>(
  checkCode: string,
  registerCode: string
): ApiResponse<T> {
  return Post<T>("/users/registration/login/by-code", {
    check_code: checkCode,
    register_code: registerCode,
  });
}

export function getLoginCode<T = { check_code: string }>(
  email: string,
  captcha_token: string
): ApiResponse<T> {
  return Post<T>("/users/registration/login/send-code", { email, captcha_token });
}

const userApi = {
  getUserInfo,
  userLoginStatus,
  userLoginAndRegister,
  checkEmailLoginStatus,
  emailApproval,
  requestResetPassword,
  resetPassword,
  updatePassword,
  register,
  tvRegister,
  registerWithPassword,
  getRegistrationCheckCode,
  getLoginCode,
  loginWithCode,
};
export default userApi;
