import React, { useRef, useState, useEffect } from "react";

import CustomInput, {
  INPUT_INVALID_STATUS,
  TInputInValidStatus,
  VALIDATION_RULES,
} from "../CustomInput";
import { workspaceNameInvalidHint } from "./invalidInputHints";

const SubmitWorkspaceName = ({
  prefilledValue,
  onSubmit,
}: {
  prefilledValue?: string;
  onSubmit: (workspaceName: string) => void;
}) => {
  const workspaceNameInputRef = useRef<HTMLInputElement>(null);
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [invalidStatus, setInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  function testInputValidation(workspaceName: string) {
    if (!workspaceName) {
      setInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (!/.{3,}/.test(workspaceName)) {
      setInvalidStatus(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    if (!VALIDATION_RULES.WORKSPACE_NAME_REGEX_PATTERN.test(workspaceName)) {
      setInvalidStatus(INPUT_INVALID_STATUS.EXCEEDED);
      return false;
    }
    setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handleWorkspaceNameInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const workspaceName = e?.target?.value || "";
    setWorkspaceName(workspaceName);
    if (invalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testInputValidation(workspaceName);
    }
  }

  function handleWorkspaceNameFinished() {
    if (testInputValidation(workspaceName)) {
      onSubmit(workspaceName);
    }
  }

  useEffect(() => {
    workspaceNameInputRef?.current?.focus();
  }, []);

  return (
    <>
      <CustomInput
        inputRef={workspaceNameInputRef}
        placeholder={prefilledValue}
        type="workspaceName"
        value={workspaceName}
        onChange={handleWorkspaceNameInputChange}
        onEnter={handleWorkspaceNameFinished}
        invalidHintContent={workspaceNameInvalidHint(invalidStatus)}
      />
      <button className="button-primary-360" onClick={handleWorkspaceNameFinished}>
        Continue
      </button>
    </>
  );
};

export default SubmitWorkspaceName;
