import React, { useRef, useState, useEffect, useMemo } from "react";

import CustomInput, {
  INPUT_INVALID_STATUS,
  InvalidHint,
  TInputInValidStatus,
  VALIDATION_RULES,
} from "../CustomInput";
import Log from "@/utils/Log";
import {
  emailInvalidHint,
  passwordInvalidHint,
  passwordCheckInvalidHint,
  usernameInvalidHint,
} from "./invalidInputHints";
import { useAuth } from "@/hooks";

const RegisterUser = ({
  fixEmail,
  prefilledEmail,
}: {
  fixEmail?: string;
  prefilledEmail?: string;
}) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>(fixEmail || prefilledEmail || "");
  const [emailInvalidStatus, setEmailInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );
  const [emailHasRegistered, setEmailHasRegistered] = useState<boolean>(false);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const [username, setUsername] = useState<string>("");
  const [usernameInvalidStatus, setUsernameInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>("");
  const [passwordInvalidStatus, setPasswordInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  const passwordInputRef2 = useRef<HTMLInputElement>(null);
  const [password2, setPassword2] = useState<string>("");
  const [passwordInvalidStatus2, setPasswordInvalidStatus2] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  const auth = useAuth();

  function testEmailValidation(email: string) {
    if (!email) {
      setEmailInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (!VALIDATION_RULES.EMAIL_REGEX_PATTERN.test(email)) {
      setEmailInvalidStatus(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    setEmailInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handleEmailInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const email = e?.target?.value?.toLowerCase() || "";
    setEmail(email);
    if (emailInvalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testEmailValidation(email);
    }
  }

  function handleEmailFinished() {
    if (testEmailValidation(email)) {
      usernameInputRef?.current?.focus();
    }
  }

  function testUsernameInputValidation(username: string) {
    if (!username) {
      setUsernameInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (!VALIDATION_RULES.USERNAME_REGEX_PATTERN.test(username)) {
      setUsernameInvalidStatus(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    setUsernameInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handleUsernameInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const username = e?.target?.value || "";
    setUsername(username);
    if (usernameInvalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testUsernameInputValidation(username);
    }
  }

  function handleUsernameFinished() {
    if (testUsernameInputValidation(username)) {
      passwordInputRef?.current?.focus();
    }
  }

  function testPasswordValidation(password: string) {
    if (!password) {
      setPasswordInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (!VALIDATION_RULES.PASSWORD_REGEX_PATTERN.test(password)) {
      setPasswordInvalidStatus(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    setPasswordInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function testPasswordValidation2(password2: string) {
    if (!password2) {
      setPasswordInvalidStatus2(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (password2 !== password) {
      setPasswordInvalidStatus2(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    setPasswordInvalidStatus2(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handlePasswordInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e?.target?.value || "";
    setPassword(password);
    if (passwordInvalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testPasswordValidation(password);
    }
  }

  function handlePasswordFinished(e?: React.FormEvent) {
    e?.preventDefault();
    if (testPasswordValidation(password)) {
      passwordInputRef2?.current?.focus();
    }
  }

  function handlePasswordInputChange2(e: React.ChangeEvent<HTMLInputElement>) {
    const password2 = e?.target?.value || "";
    setPassword2(password2);
    if (passwordInvalidStatus2 !== INPUT_INVALID_STATUS.INITIAL) {
      testPasswordValidation2(password2);
    }
  }
  function handlePasswordInputFocus2() {
    testPasswordValidation(password);
  }

  function handlePasswordFinished2(e?: React.FormEvent) {
    e?.preventDefault();
    if (
      testEmailValidation(email) &&
      testUsernameInputValidation(username) &&
      testPasswordValidation(password) &&
      testPasswordValidation2(password2)
    ) {
      Log.debug("register user info: ", email, username, password);
      auth.registerUserWithPassword(email, username, password).then(({ emailHasRegistered }) => {
        setEmailHasRegistered(emailHasRegistered);
      });
    }
  }

  useEffect(() => {
    emailInputRef?.current?.focus();
  }, []);

  const invalidEmailAccountHint = useMemo(
    () =>
      emailHasRegistered ? (
        <InvalidHint content="This email address has already been registered. Please sign in." />
      ) : null,
    [emailHasRegistered]
  );

  return (
    <form>
      <CustomInput
        disabled={!!fixEmail}
        inputRef={emailInputRef}
        type="email"
        value={email}
        autoComplete="off"
        onChange={handleEmailInputChange}
        onBlur={handleEmailFinished}
        onEnter={handleEmailFinished}
        invalidHintContent={invalidEmailAccountHint || emailInvalidHint(emailInvalidStatus)}
      />
      <CustomInput
        inputRef={usernameInputRef}
        type="username"
        value={username}
        autoComplete="off"
        onChange={handleUsernameInputChange}
        onBlur={handleUsernameFinished}
        onEnter={handleUsernameFinished}
        invalidHintContent={usernameInvalidHint(usernameInvalidStatus)}
      />
      <CustomInput
        inputRef={passwordInputRef}
        type="password"
        value={password}
        autoComplete="off"
        onChange={handlePasswordInputChange}
        onBlur={handlePasswordFinished}
        onEnter={handlePasswordFinished}
        invalidHintContent={passwordInvalidHint(password, passwordInvalidStatus)}
      />
      <CustomInput
        inputRef={passwordInputRef2}
        type="password"
        placeholder="Password confirm"
        value={password2}
        autoComplete="off"
        onChange={handlePasswordInputChange2}
        onFocus={handlePasswordInputFocus2}
        onBlur={handlePasswordFinished2}
        onEnter={handlePasswordFinished2}
        invalidHintContent={passwordCheckInvalidHint(passwordInvalidStatus2)}
      />

      <button className="button-primary-360" onClick={handlePasswordFinished2}>
        Continue
      </button>
    </form>
  );
};

export default RegisterUser;
