import React, { useEffect, useMemo } from "react";
import CustomLayout from "@/components/CustomLayout";
import VibeTitle from "@/components/VibeTitle";
import constants, { routes } from "@/constants";

import { SelectionItem } from "./components/SelectionItem";
import { addRedirectParam, compareTeamByRoleAndName, compareTeamByInvitationTime } from "@/utils";

import { useAuth, useRedirectNavigation } from "@/hooks";
import { ITeamInfoObj } from "@/api/interface";
import JoinWorkspaceSelections from "./components/JoinWorkspaceSelections";
import JoinWorkspacePopup from "./components/JoinWorkspacePopup";
import Log from "@/utils/Log";
import { ISelectionItem } from "./interface";

const JoinAndSelectWorkspace = () => {
  const auth = useAuth();
  const navigate = useRedirectNavigation();
  const accessToken = localStorage.getItem(constants.ACCESS_TOKEN) || "";

  const teamList: ISelectionItem[] = useMemo(
    () =>
      auth?.teamInfoList
        .map((team) => ({
          ...team,
          href: addRedirectParam(auth.redirectUrl, team.teamId, accessToken),
        }))
        .sort(compareTeamByRoleAndName),
    [auth?.teamInfoList]
  );

  const teamInvitations: ITeamInfoObj[] = useMemo(
    () => auth?.teamInvitationList.sort(compareTeamByInvitationTime),
    [auth?.teamInvitationList]
  );

  const teamSelectionContent = useMemo(
    () =>
      teamList.length ? (
        <div className="selection-wrapper">
          {teamList.map((team, index, arr) => (
            <SelectionItem
              key={team.teamId}
              className={index === arr.length - 1 ? "last-item" : ""}
              {...team}
            />
          ))}
        </div>
      ) : null,
    [teamList]
  );

  useEffect(() => {
    if (!auth?.teamInfoList?.length && !auth?.teamInvitationList?.length) {
      Log.debug("team info list is empty");
      navigate(routes.corsSync);
    }
    localStorage.removeItem(constants.TEAM_ID);
  }, []);

  return (
    <CustomLayout contentClassName="join-select-workspace">
      {!teamList.length ? (
        <JoinWorkspaceSelections teamInvitations={teamInvitations} />
      ) : (
        <>
          <VibeTitle
            title="Select a workspace"
            subtitle="You can switch to another workspace anytime. "
          />
          {teamSelectionContent}
          <JoinWorkspacePopup teamInvitations={teamInvitations} />
        </>
      )}
    </CustomLayout>
  );
};

export default JoinAndSelectWorkspace;
