import React, { useRef, useState, useEffect } from "react";

import CustomInput, {
  INPUT_INVALID_STATUS,
  TInputInValidStatus,
  VALIDATION_RULES,
} from "../CustomInput";
import Log from "@/utils/Log";
import { passwordInvalidHint, passwordCheckInvalidHint } from "./invalidInputHints";

const DoubleCheckPassword = ({
  email,
  onPasswordChecked,
}: {
  email: string;
  onPasswordChecked: (newPassword: string) => void;
}) => {
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>("");
  const [invalidStatus, setInvalidStatus] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  const passwordInputRefCheck = useRef<HTMLInputElement>(null);
  const [passwordCheck, setPasswordCheck] = useState<string>("");
  const [invalidStatusCheck, setInvalidStatusCheck] = useState<TInputInValidStatus>(
    INPUT_INVALID_STATUS.INITIAL
  );

  function testInputValidation(password: string) {
    if (!password) {
      setInvalidStatus(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (!VALIDATION_RULES.PASSWORD_REGEX_PATTERN.test(password)) {
      setInvalidStatus(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    setInvalidStatus(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function testInputValidationCheck(passwordCheck: string) {
    if (!passwordCheck) {
      setInvalidStatusCheck(INPUT_INVALID_STATUS.EMPTY);
      return false;
    }
    if (passwordCheck !== password) {
      setInvalidStatusCheck(INPUT_INVALID_STATUS.INVALID);
      return false;
    }
    setInvalidStatusCheck(INPUT_INVALID_STATUS.INITIAL);
    return true;
  }

  function handlePasswordInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e?.target?.value || "";
    setPassword(password);
    if (invalidStatus !== INPUT_INVALID_STATUS.INITIAL) {
      testInputValidation(password);
    }
  }

  function handlePasswordFinished(e?: React.FormEvent) {
    e?.preventDefault();
    if (testInputValidation(password)) {
      passwordInputRefCheck?.current?.focus();
    }
  }

  function handlePasswordInputChangeCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const passwordCheck = e?.target?.value || "";
    setPasswordCheck(passwordCheck);
    if (invalidStatusCheck !== INPUT_INVALID_STATUS.INITIAL) {
      testInputValidationCheck(passwordCheck);
    }
  }
  function handlePasswordInputFocusCheck() {
    testInputValidation(password);
  }

  function handlePasswordFinishedCheck(e?: React.FormEvent) {
    e?.preventDefault();
    if (testInputValidation(password) && testInputValidationCheck(passwordCheck)) {
      Log.debug("new password is: ", password);
      onPasswordChecked(password);
    }
  }

  useEffect(() => {
    passwordInputRef?.current?.focus();
  }, []);

  return (
    <form>
      <input autoComplete="email" className="is-display-none" type="email" defaultValue={email} />
      <CustomInput
        inputRef={passwordInputRef}
        type="password"
        autoComplete="off"
        value={password}
        onChange={handlePasswordInputChange}
        onEnter={handlePasswordFinished}
        invalidHintContent={passwordInvalidHint(password, invalidStatus)}
      />
      <CustomInput
        inputRef={passwordInputRefCheck}
        type="password"
        autoComplete="off"
        placeholder="Password confirm"
        value={passwordCheck}
        onChange={handlePasswordInputChangeCheck}
        onFocus={handlePasswordInputFocusCheck}
        onEnter={handlePasswordFinishedCheck}
        invalidHintContent={passwordCheckInvalidHint(invalidStatusCheck)}
      />

      <button className="button-primary-360" onClick={handlePasswordFinishedCheck}>
        Continue
      </button>
    </form>
  );
};

export default DoubleCheckPassword;
