import React, { useState } from "react";
import getInputPrefixIcon from "./components/InputPrefixIcon";
import ShowPasswordIcon from "./components/ShowPasswordIcon";

import type { CustomInputPropTypes } from "./interface";
import { commonInputTypes, getInputConfigs } from "./configs";
import "./styles.css";

const enterKeyType = "Enter";

const CustomInput = (props: CustomInputPropTypes) => {
  const {
    className = "",
    invalidHintContent,
    onEnter,
    prefixIcon,
    onKeyDown,
    inputRef,
    type,
    disabled,
    ...restProps
  } = props;
  const { password, text } = commonInputTypes;
  const isPassword = type === password;
  const isInvalid = !!invalidHintContent;
  const builtInPrefixIcon = getInputPrefixIcon(type);
  const defaultConfigs = getInputConfigs(type);

  const defaultType = defaultConfigs?.type || type || text;
  const [inputType, setInputType] = useState<string>(defaultType);

  function handleChangePasswordVisibility() {
    if (!isPassword) return;
    setInputType(inputType === password ? text : password);
  }

  function handleKeyDownEvent(e: React.KeyboardEvent<HTMLInputElement>) {
    onKeyDown?.(e);
    if (e?.key === enterKeyType) {
      e?.preventDefault();
      onEnter?.();
    }
  }

  return (
    <>
      <div
        className={`input-affix-wrapper ${className ? className : ""}${
          isPassword ? " is-password" : ""
        }${isInvalid ? " is-invalid" : ""}${disabled ? " disabled" : ""}`}
      >
        <span className="prefix-icon">{prefixIcon || builtInPrefixIcon}</span>
        <input
          onKeyDown={handleKeyDownEvent}
          ref={inputRef}
          type={inputType}
          disabled={disabled}
          {...defaultConfigs}
          {...restProps}
        />
        {isPassword && (
          <div className="show-password-wrapper" onClick={handleChangePasswordVisibility}>
            <ShowPasswordIcon showPassword={inputType === text} />
          </div>
        )}
      </div>
      {invalidHintContent}
    </>
  );
};

export default CustomInput;
