import React from "react";
import {
  INPUT_INVALID_STATUS,
  InvalidHint,
  passwordInvalidContent,
  TInputInValidStatus,
} from "../CustomInput";

export const emailInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.EMPTY:
      return <InvalidHint content="Field has an invalid pattern" />;
    case INPUT_INVALID_STATUS.INVALID:
      return <InvalidHint content="Incorrect email format" />;
    default:
      return null;
  }
};

export const usernameInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.EMPTY:
      return <InvalidHint content="Field has an invalid pattern" />;
    case INPUT_INVALID_STATUS.INVALID:
      return <InvalidHint content="Incorrect username format" />;
    case INPUT_INVALID_STATUS.EXCEEDED:
      return <InvalidHint content="Character limit (30) reached" />;
    default:
      return null;
  }
};

export const passwordInvalidHint = (value: string, invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.EMPTY:
      return <InvalidHint content="Field has an invalid pattern" />;
    case INPUT_INVALID_STATUS.INVALID:
      return passwordInvalidContent.map((hint) => (
        <InvalidHint
          key={hint.content}
          className="new-password"
          isInvalid={!hint.pattern.test(value)}
          content={hint.content}
          visibleValid
        />
      ));
    default:
      return null;
  }
};

export const passwordCheckInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.EMPTY:
      return <InvalidHint content="Field has an invalid pattern" />;
    case INPUT_INVALID_STATUS.INVALID:
      return <InvalidHint content="Does not match new password" />;
    default:
      return null;
  }
};

export const workspaceNameInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.EMPTY:
      return <InvalidHint content="Field has an invalid pattern" />;
    case INPUT_INVALID_STATUS.INVALID:
      return <InvalidHint content="Incorrect workspaceName format" />;
    case INPUT_INVALID_STATUS.EXCEEDED:
      return <InvalidHint content="Character limit (100) reached" />;
    default:
      return null;
  }
};

export const codeInvalidHint = (invalidStatus: TInputInValidStatus) => {
  switch (invalidStatus) {
    case INPUT_INVALID_STATUS.INVALID:
      return (
        <>
          <InvalidHint content="The code you entered is incorrect." className="margin-bottom-0" />
          <InvalidHint content="Please try again or click Resend code." />
        </>
      );
    default:
      return null;
  }
};
