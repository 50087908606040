/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InternalAxiosRequestConfig } from "axios";
import message from "@/components/CustomMessage";

import Log from "@/utils/Log";
import constants from "@/constants";

// function csrfTokenRequired(method: string) {
//   return method === "POST" || method === "PUT" || method === "DELETE";
// }

export const handleConfigureAuth = (config: InternalAxiosRequestConfig<any>) => {
  config.headers[constants.X_CSRF_TOKEN] = sessionStorage.getItem(constants.X_CSRF_TOKEN);
  return config;
};

export const handleNetworkError = (errStatus?: number): void => {
  const networkErrMap: any = {
    "400": "Error Request", // token invalid
    "401": "Unauthorized, please log in again",
    "403": "Access denied",
    "404": "Request error, resource not found",
    "405": "Request method not allowed",
    "408": "Request timeout",
  };
  if (errStatus === 400 || errStatus === 403) {
    sessionStorage.removeItem(constants.VIBE_CSRF_TOKEN);
  }
  const hideErrorMsg = sessionStorage.getItem(constants.HIDE_ERROR_MSG);
  if (hideErrorMsg && (errStatus === 403 || errStatus === 404)) {
    Log.debug("do not pop error message", networkErrMap[errStatus]);
    sessionStorage.removeItem(constants.HIDE_ERROR_MSG);
    return;
  }
  if (errStatus) {
    const errorMsg = networkErrMap[errStatus] ?? `Other Connect Error: ${errStatus}`;
    Log.error(errorMsg);
    return;
  }
  const errorMsg = "Cannot connect Internet!";
  Log.error(errorMsg);
};

export const handleGeneralError = (errStatus?: number): void => {
  const generalErrMap: any = {
    "1008": "Invalid X-Csrf-Token",
  };
  if (errStatus && generalErrMap.hasOwnProperty(errStatus)) {
    Log.error(generalErrMap[errStatus]);
    message.error(generalErrMap[errStatus]);
  }
};

export const handleAuthError = (errStatus?: number): void => {
  const authErrMap: any = {
    "500": "Server-side error",
    "501": "Network not implemented",
    "502": "Network error",
    "503": "Service unavailable",
    "504": "Network timeout",
    "505": "HTTP version not supported for this request",
    "1003": "Not Authenticated",
  };

  if (errStatus && authErrMap.hasOwnProperty(errStatus)) {
    Log.error(authErrMap[errStatus]);
    message.error(authErrMap[errStatus]);
  }
};
