import { TCommonInputTypes } from "./interface";

export const commonInputTypes: Record<TCommonInputTypes, TCommonInputTypes> = {
  email: "email",
  password: "password",
  username: "username",
  text: "text",
};

const EMAIL_REGEX_PATTERN =
  /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const USERNAME_REGEX_PATTERN = /^.{3,30}$/;
const PASSWORD_REGEX_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()-=_+{};:'",.<>/?\\[\]`~|]{8,}$/;
const WORKSPACE_NAME_REGEX_PATTERN = /^.{3,100}$/;

export const VALIDATION_RULES = {
  EMAIL_REGEX_PATTERN,
  USERNAME_REGEX_PATTERN,
  PASSWORD_REGEX_PATTERN,
  WORKSPACE_NAME_REGEX_PATTERN,
};

export function getInputConfigs(type?: string) {
  const { email, username, password } = commonInputTypes;
  switch (type) {
    case email:
      return {
        name: "email",
        autoComplete: "email",
        placeholder: "Email",
      };
    case username:
      return {
        type: "text",
        name: "username",
        placeholder: "Name",
        autoComplete: "name",
      };
    case password:
      return {
        name: "password",
        placeholder: "Password",
        autoComplete: "current-password",
      };
    default:
      return;
  }
}

export const passwordInvalidContent = [
  {
    pattern: /^.{8,30}$/,
    content: "Must contain at least 8 characters",
  },
  {
    pattern: /^(?=.*[A-Za-z])(?=.*\d).*$/,
    content: "Must use both letters and numbers",
  },
  {
    pattern: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
    content: "Must contain both lowercase and uppercase letters",
  },
];
