import React from "react";

import { SubmitEmail } from "@/components/CustomForm";
import VibeTitle from "@/components/VibeTitle";
import Link from "@/components/CustomLink";
import { routes } from "@/constants";
import { useAuth } from "@/hooks";
import CustomLayout from "@/components/CustomLayout";

const RequestResetPassword = ({ onSentSuccess }: { onSentSuccess: VoidFunction }) => {
  const auth = useAuth();

  function handleContinueClick(email: string) {
    auth.requestResetPassword(email);
    onSentSuccess();
  }

  return (
    <CustomLayout>
      <VibeTitle title="Forgot your password?" subtitle="Enter your Vibe account email address" />
      <SubmitEmail prefilledEmail={auth?.authState?.email} onSubmit={handleContinueClick} />
      <div className="bottom-link-wrapper password-links-wrapper">
        <Link className="link" to={routes.login}>
          Back to sign in
        </Link>
      </div>
    </CustomLayout>
  );
};

export default RequestResetPassword;
