// constants
const DEFAULT_SIGN_IN_APP = "one";

const ACCESS_TOKEN = "access_token";
const VIBE_CSRF_TOKEN = "vibe-csrf-token";
const X_CSRF_TOKEN = "X-Csrf-Token";
const REDIRECT_FROM = "signin-vibe";
const REDIRECT_TO = "redirect-to";
const JOIN_TEAM_INFO = "join_team_info";

// app name map
const SIGN_IN_APP_NAMES: Record<SignInApps, SignInApps> = {
  canvas: "canvas",
  admin: "admin",
  one: "one",
  website: "website",
};

// admin invalid
const INVALID_ACCOUNT = "account";
const NO_AVAILABLE_WORKSPACE = "no-available-workspace";

// workspace
const TEAM_ID = "team_id";

// email approval
const EMAIL_KEY = "email";
const PLATFORM_KEY = "platform";
const TIME_KEY = "t";
const IP_KEY = "ip";

const infoQueryKeys = [PLATFORM_KEY, TIME_KEY, EMAIL_KEY, IP_KEY];

const CHECK_CODE = "check_code";
const REGISTER_CODE = "register_code";

const REQUEST_ID = "request_id";
const RESOLVE_KEY = "resolve_key";

const HAS_PASSWORD = "has_password";

const REGISTRATION_INFO = "registration_info";

const HIDE_ERROR_MSG = "hide_error_msg";

export const constants = {
  SIGN_IN_APP_NAMES,
  DEFAULT_SIGN_IN_APP,
  ACCESS_TOKEN,
  VIBE_CSRF_TOKEN,
  X_CSRF_TOKEN,
  REDIRECT_FROM,
  REDIRECT_TO,
  JOIN_TEAM_INFO,
  TEAM_ID,
  INVALID_ACCOUNT,
  NO_AVAILABLE_WORKSPACE,
  EMAIL_KEY,
  CHECK_CODE,
  REGISTER_CODE,
  REQUEST_ID,
  RESOLVE_KEY,
  HAS_PASSWORD,
  infoQueryKeys,
  REGISTRATION_INFO,
  HIDE_ERROR_MSG,
};
