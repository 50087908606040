import { ReactNode } from "react";

export interface InvalidHintProps extends React.ParamHTMLAttributes<HTMLElement> {
  content: string;
  isInvalid?: boolean;
  visibleValid?: boolean;
}

export type TCommonInputTypes = "email" | "username" | "password" | "text";

export type TInputInValidStatus = "INITIAL" | "EMPTY" | "INVALID" | "EXCEEDED" | "EXPIRED";

export const INPUT_INVALID_STATUS: Record<TInputInValidStatus, TInputInValidStatus> = {
  INITIAL: "INITIAL",
  EMPTY: "EMPTY",
  INVALID: "INVALID",
  EXCEEDED: "EXCEEDED",
  EXPIRED: "EXPIRED",
};

export interface CustomInputPropTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  invalidHintContent?: ReactNode;
  onEnter?: VoidFunction;
  prefixIcon?: ReactNode;
  inputRef?: React.LegacyRef<HTMLInputElement>;
  disabled?: boolean;
}
