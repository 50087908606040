import React, { useMemo, useState } from "react";
import constants, { routes } from "@/constants";

import CustomLayout from "@/components/CustomLayout";
import Link from "@/components/CustomLink";
import VibeTitle from "@/components/VibeTitle";
import { SubmitEmail } from "@/components/CustomForm";
import BottomInvalidAdminHint from "./components/BottomInvalidAdminHint";
import SSOLoginBtns from "@/components/SSOLoginBtns";

import { useAuth } from "@/hooks";
import { getQueryValueByKeys } from "@/utils";
import Log from "@/utils/Log";
import { InvalidHint } from "@/components/CustomInput";

const Login = () => {
  const auth = useAuth();
  const isAdmin = (auth.signInFrom as SignInApps) === "admin";
  const invalidTypes = (isAdmin && auth?.authState?.invalidTypes) || "";
  const [unregisteredAccount, setUnregisteredAccount] = useState<boolean>(false);
  const prefilledEmail =
    getQueryValueByKeys(constants.EMAIL_KEY) ||
    auth?.authState?.email ||
    sessionStorage.getItem(constants.EMAIL_KEY);

  const handleEmailSubmit = (email: string) => {
    Log.debug("handle submitted email: ", email);
    auth.trySignInWithSSO(email).then((unregisteredAccount) => {
      if (unregisteredAccount) {
        Log.debug("is unregistered Account: ", unregisteredAccount);
        setUnregisteredAccount(true);
      }
    });
  };

  const registerLink = useMemo(
    () => (
      <div className="bottom-link-wrapper">
        <span className="title">{"Don't have an account?"}</span>
        <Link className="title link" to={routes.register}>
          {"Sign up now"}
        </Link>
      </div>
    ),
    []
  );

  const errorHint = useMemo(
    () =>
      unregisteredAccount ? <InvalidHint content="We couldn't find your Vibe account." /> : null,
    [unregisteredAccount]
  );

  const loginFooter = useMemo(() => {
    Log.debug("invalidTypes: ", invalidTypes);
    return !invalidTypes ? SSOLoginBtns() : null;
  }, [invalidTypes]);

  return (
    <CustomLayout contentClassName="login-page" footer={loginFooter}>
      <VibeTitle title={"Sign in to your Vibe account"} />
      <SubmitEmail
        prefilledEmail={prefilledEmail}
        onSubmit={handleEmailSubmit}
        errorHint={errorHint}
      />
      {!invalidTypes ? registerLink : null}
      <BottomInvalidAdminHint type={invalidTypes} />
    </CustomLayout>
  );
};

export default Login;
