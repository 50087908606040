import React, { useEffect, useMemo } from "react";
import { CheckPassword } from "@/components/CustomForm";
import Link from "@/components/CustomLink";
import VibeTitle from "@/components/VibeTitle";
import constants, { routes } from "@/constants";
import { useAuth, useRedirectNavigation } from "@/hooks";
import Log from "@/utils/Log";
import CustomLayout from "@/components/CustomLayout";

const SignWithCodeOrPassword = () => {
  const auth = useAuth();
  const navigate = useRedirectNavigation();
  const email = auth?.authState?.email || sessionStorage.getItem(constants.EMAIL_KEY) || "";

  useEffect(() => {
    if (!email) {
      Log.error("missing user email");
      navigate(routes.login);
    }
  }, []);

  const title = useMemo(
    () => (
      <>
        Sign in to <span className="blue-text">{email}</span>
      </>
    ),
    [email]
  );

  const handleRequestLoginCode = () => {
    auth.requestLoginCode(email);
  };

  return (
    <CustomLayout contentClassName="login-page">
      <VibeTitle title={title} />
      <button className="text-button code-verification-button" onClick={handleRequestLoginCode}>
        Continue with verification code
      </button>
      <div className="or-gap">Or</div>
      <CheckPassword email={email} />
      <div className="bottom-link-wrapper">
        <Link className="link" to={routes.forgotPassword}>
          Forgot password
        </Link>
      </div>
    </CustomLayout>
  );
};

export default SignWithCodeOrPassword;
